import React, { useMemo } from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { cn } from "@/util";
import NestedLessonContentBlockDisplay from "../../NestedLessonContentBlockDisplay";
import { HoveredIndexes, useHoveredIndexes } from "./useHoveredIndexes";
import ColumnEditor from "./ColumnEditor";
import { SortableTR } from "./SortableTRWrapper";
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    SortableContext,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useSaveContentBlock } from "@/Pages/ELearning/LessonDesign/content-block-management/useSaveContentBlock";
import { useContentBlockState } from "@/Pages/ELearning/LessonDesign/useContentBlockState";

type Props = {
    contentBlock: ContentBlockShape;
    children: ContentBlockShape[];
    isInAdminContext: boolean;
};


export function LessonTable({
    contentBlock,
    children,
    isInAdminContext,
}: Props) {
    const thead = children.find(
        (block) => block.content_block_type === ContentBlockType["Table Head"],
    );
    const tbody = children.find(
        (block) => block.content_block_type === ContentBlockType["Table Body"],
    );

    const rows = useMemo(
        () => [
            ...(thead?.contentBlocks || []),
            ...(tbody?.contentBlocks || []),
        ],
        [thead, tbody],
    );

    const { handleMouseEnter, handleMouseLeave, hoveredIndexes } =
        useHoveredIndexes();

    return (
        <div
            className={`relative ${cn(
                "has-[.cursor-wait]:animate-pulse",
                "has-[.cursor-wait]:cursor-wait", // "has-[.cursor-wait]:pointer-events-none",
                contentBlock.theme.tailwindClasses,
            )}`}
            style={{ borderColor: contentBlock?.theme?.borderColor }}
        >
            <table
                className={cn(
                    "has-[.cursor-wait]:animate-pulse",
                    "has-[.cursor-wait]:cursor-wait",
                    // "has-[.cursor-wait]:pointer-events-none",
                    contentBlock.theme.tailwindClasses,
                )}
                data-content-block-id={contentBlock.id}
                style={{ borderColor: contentBlock?.theme?.borderColor }}
            >
                {!!tbody && (
                    <OptionallyWrappedTBody
                        tbody={tbody}
                        rows={rows}
                        isInAdminContext={isInAdminContext}
                    >
                        {rows.map((tr, i) => (
                            <>
                                <LessonTableRow
                                    key={tr.id}
                                    contentBlock={tr}
                                    isInAdminContext={isInAdminContext}
                                    children={tr.contentBlocks}
                                    index={i}
                                    table={contentBlock}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    hoveredIndexes={hoveredIndexes}
                                />
                            </>
                        ))}
                    </OptionallyWrappedTBody>
                )}
            </table>
        </div>
    );
}

function OptionallyWrappedTBody({
    tbody,
    children,
    isInAdminContext,
    rows,
}: {
    tbody: ContentBlockShape;
    rows: ContentBlockShape[];
    children: JSX.Element[];
    isInAdminContext: boolean;
}) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    if (!isInAdminContext) {
        return (
            <tbody
                data-content-block-id={tbody.id}
                className={cn(tbody.theme?.tailwindClasses)}
            >
                {children}
            </tbody>
        );
    }

    const { setContentBlock, contentBlocks } = useContentBlockState();
    const { mutateAsync } = useSaveContentBlock();

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(e) => {
                const movedBlock = contentBlocks[e.active.id];
                const destinationBlock = contentBlocks[e.over.id];
                const newWeight = destinationBlock?.weight;

                if (
                    movedBlock?.id === destinationBlock?.id ||
                    !destinationBlock ||
                    !movedBlock
                )
                    return;

                const index = rows
                    .sort((a, b) => a.weight - b.weight)
                    .findIndex((tr) => tr.id === destinationBlock.id);

                const filteredBlocks = rows.filter(
                    (block) => block.id !== movedBlock.id,
                );
                const directionMoved = movedBlock.weight > newWeight ? 0 : 1;

                const arrangedTable = {
                    ...tbody,
                    contentBlocks: [
                        ...filteredBlocks.slice(0, index + directionMoved),
                        { ...movedBlock, weight: newWeight },
                        ...filteredBlocks.slice(index),
                    ].map((block, i) => ({ ...block, weight: i })),
                };

                setContentBlock(arrangedTable);
                mutateAsync(arrangedTable);
            }}
        >
            <SortableContext
                items={
                    rows as (ContentBlockShape & {
                        id: string;
                    })[]
                }
            >
                <tbody
                    data-content-block-id={tbody.id}
                    className={cn(tbody.theme?.tailwindClasses)}
                >
                    {children}
                </tbody>
            </SortableContext>
        </DndContext>
    );
}

export function LessonTableRow({
    contentBlock,
    children,
    isInAdminContext,
    index,
    table,
    onMouseEnter,
    onMouseLeave,
    hoveredIndexes,
}: Props & {
    index: number;
    table: ContentBlockShape;
    onMouseEnter: (hoveredIndexes: HoveredIndexes) => void;
    onMouseLeave: () => void;
    hoveredIndexes: HoveredIndexes;
}) {
    return (
        <SortableTR
            contentBlock={contentBlock}
            isInAdminContext={isInAdminContext}
            rowIndex={index}
        >
            {children.map((td, i) => (
                <LessonTableCell
                    contentBlock={td}
                    isInAdminContext={isInAdminContext}
                    index={i}
                    key={td.id}
                    table={table}
                    rowIndex={index}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    hoveredIndexes={hoveredIndexes}
                >
                    {td.contentBlocks.map((contentBlock) => (
                        <NestedLessonContentBlockDisplay
                            key={contentBlock.id}
                            isInAdminContext={isInAdminContext}
                            contentBlock={contentBlock}
                        />
                    ))}
                </LessonTableCell>
            ))}
        </SortableTR>
    );
}

export function LessonTableCell({
    contentBlock,
    children,
    isInAdminContext,
    index,
    rowIndex,
    table,
    onMouseEnter,
    onMouseLeave,
    hoveredIndexes,
}: {
    contentBlock: ContentBlockShape;
    children: JSX.Element[] | JSX.Element;
    isInAdminContext: boolean;
    index: number;
    rowIndex: number;
    table: ContentBlockShape;
    onMouseEnter: (hoveredIndexes: HoveredIndexes) => void;
    onMouseLeave: () => void;
    hoveredIndexes: HoveredIndexes;
}) {
    const Tag =
        contentBlock.content_block_type ===
        ContentBlockType["Table Header Cell"]
            ? "th"
            : "td";

    if (isInAdminContext) {
        return (
            <Tag
                className={`group ${cn(
                    "relative",
                    "group/td",
                    "first:border-l-0",
                    "last:border-r-0",
                    contentBlock.theme.tailwindClasses,
                )}`}
                data-content-block-id={contentBlock.id}
                data-weight={contentBlock.weight}
                data-parent-id={contentBlock.parent_content_block_id}
                data-index={index}
                style={{ borderColor: table?.theme?.borderColor }}
                onMouseEnter={() => onMouseEnter({ x: index, y: rowIndex })}
                onMouseLeave={onMouseLeave}
            >
                {index === 0 && (
                    <ColumnEditor
                        table={table}
                        column={index}
                        isShown={hoveredIndexes.x === index}
                        td={contentBlock}
                    />
                )}
                {children}
            </Tag>
        );
    }

    return (
        <Tag className={cn(contentBlock.theme.tailwindClasses)}>{children}</Tag>
    );
}
